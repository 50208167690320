import ActionType from './actionType'
import StateType from './stateType'
import { ActionTypes as types } from './actions'

const Reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case types.SET_ORDERHISTORYFILTER:
      return {
        ...state,
        orderHistoryFilter: action.data,
      }

    default:
      return state
  }
}

export default Reducer
