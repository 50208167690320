import React, { createContext, useReducer, useContext } from 'react'
import reducer from './reducer'
import StateType from './stateType'
import dateOptions from '../components/dateOptions'

const defaultState: StateType = {
  orderHistoryFilter: {
    dateIsFreeText: false,
    filterByStartDate: null,
    filterByEndDate: null,
    filterByStartDateFreeText: '',
    filterByEndDateFreeText: '',
    seeAllOrders: false,
    onlyOpenOrders: false,
    showOnlyUnpaid: false,
    datePeriod: dateOptions[6].value,
  },
}

const StateContext: React.Context<StateType> = createContext(defaultState)
const DispatchContext: React.Context<{}> = createContext({})

const StateContainer: any = (statecontext: React.Context<StateType>) => {
  const context = useContext(statecontext)
  if (context === undefined) {
    throw new Error('StateContext must be used within a StateProvider')
  }
  return context
}

const DispatchContainer: any = (dispatchcontext: React.Context<{}>) => {
  const context = useContext(dispatchcontext)
  if (context === undefined) {
    throw new Error('DispatchContext must be used within a StateProvider')
  }
  return context
}

const StateProvider = (props: { children: React.ReactNode }) => {
  const { children } = props

  const [state, dispatch] = useReducer(reducer, defaultState)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

const useStateContext = () => {
  return [StateContainer(StateContext), DispatchContainer(DispatchContext)]
}

export { StateProvider, useStateContext }
