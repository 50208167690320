import { DatePeriodType } from '../graphql/generated/graphql-types'

const dateOptions = [
  { value: DatePeriodType.All, label: 'Filter_DateOption_All' },
  {
    label: 'Filter_DateOption_LastWeek',
    value: DatePeriodType.OneWeek,
  },
  {
    label: 'Filter_DateOption_LastTwoWeeks',
    value: DatePeriodType.TwoWeeks,
  },
  {
    label: 'Filter_DateOption_LastMonth',
    value: DatePeriodType.OneMonth,
  },
  {
    label: 'Filter_DateOption_LastThreeMonths',
    value: DatePeriodType.ThreeMonths,
  },
  {
    label: 'Filter_DateOption_LastSixMonths',
    value: DatePeriodType.SixMonths,
  },
  {
    label: 'Filter_DateOption_LastTwelveMonths',
    value: DatePeriodType.TwelveMonths,
  },
  {
    label: 'Filter_DateOption_EnterDate',
    value: DatePeriodType.Specified,
  },
]

export default dateOptions
