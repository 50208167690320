import '@centralpoint.io/dustin-ds/styles'
import DustinApp from '@dustin-web/microsite-nextjs/src/pages/_app'
import { StateProvider } from '../context/context'
import devUtils from '../dev-utils.json'

import '../components/layout/index.scss'

const App = ({ Component, pageProps, router }: any) => {
  return (
    <StateProvider>
      <DustinApp devUtils={devUtils} router={router} Component={Component} pageProps={pageProps} />
    </StateProvider>
  )
}

export default App
